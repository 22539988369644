import React, { createContext, useContext, useState, useEffect } from "react";
import { TExam, TSub } from "../pages/configurations/components/ExamsConfigs";
import apiRequest from "../helpers/apiRequest";
import { Urls } from "../utils/urls";
import { EDLevel } from "../pages/configurations/components/EducationLevels";
import { TuploadMetaData } from "../components/modals/UploadingModal";

interface AppContextType {
  educationLevels: EDLevel[] | null;
  exams: TExam[] | null;
  uploading: number | null;
  uploadMetaData: TuploadMetaData;
  setUploadMetaData: (data:TuploadMetaData) => void;
  setExams: (data: TExam[] | null) => void;
  setEducationLevels: (data: EDLevel[] | null) => void;
  setUploading: (data: number | null) => void;
  fetchExams: () => Promise<void>;
  fetchEducationLevels: () => Promise<void>;
}

const AppContext = createContext<AppContextType>({
  educationLevels: null,
  exams: null,
  uploading: null,
  uploadMetaData: null,
  setUploading: () => {},
  setExams: () => {},
  setEducationLevels: () => {},
  fetchExams: async () => {},
  fetchEducationLevels: async () => {},
  setUploadMetaData: () => {},
});

export const useAppProvider = () => useContext(AppContext);

export const AppProvider: React.FC<any> = ({ children }) => {
  const [exams, setExams] = useState<TExam[] | null>(null);
  const [educationLevels, setEducationLevels] = useState<EDLevel[] | null>(null);
  const [uploading, setUploading] = useState<number | null>(null); // Changed to null instead of 0
  const [uploadMetaData, setUploadMetaData] = useState<TuploadMetaData>(null);

  const fetchExams = async () => {
    const { data, error } = await apiRequest<{ exams: TExam[] }>({
      method: "GET",
      url: Urls.all_exams,
    });
    if (error) {
      console.error(error); // Log error for debugging
      // Optionally, show an error message on the UI
    } else if (data) {
      setExams(data.exams);
    }
  };

  const fetchEducationLevels = async () => {
    const { data, error } = await apiRequest<{ educational_levels: EDLevel[] }>({
      method: "GET",
      url: Urls.all_ed_levels,
    });
    if (error) {
      console.error(error); // Log error for debugging
      // Optionally, show an error message on the UI
    } else if (data) {
      setEducationLevels(data.educational_levels);
    }
  };

  return (
    <AppContext.Provider
      value={{
        exams,
        uploading,
        setUploading,
        setExams,
        fetchExams,
        educationLevels,
        setEducationLevels,
        fetchEducationLevels,
        uploadMetaData,
        setUploadMetaData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
