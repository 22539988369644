import React, { useEffect, useState } from "react";
import ModalBase from "./ModalBase";
import apiRequest from "../../helpers/apiRequest";
import axios from "axios";

export type TuploadMetaData = {
  fileToUpload: File;
  fileKey: string;
  fileType: string;
} | null;
type TProps = {
  uploading: number | null;
  setUploading: (data: number | null) => void;
  uploadData: TuploadMetaData;
  setUploadMetaData: (data: TuploadMetaData) => void;
};
const UploadingModal = ({ uploadData, setUploadMetaData }: TProps) => {
  const [uploading, setUploading] = useState<number | null>(null);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lastUploaded, setLastUploaded] = useState(1);
  const [uploadedPartDatas, setuploadedPartDatas] = useState<any[]>([]);
  const [uploadId, setUploadId] = useState<any>("");
  // let uploadedPartDatas: any = [];
  // let uploadId = "";

  const uploadPartsHandler = async (uploadId: string) => {
    setError(false);
    let uploadedPartDatasTmp: any[] = uploadedPartDatas;
    try {
      if (!uploadData) return;
      const parts = splitFileIntoParts(uploadData?.fileToUpload!);
      let id = uploadedPartDatasTmp.length + 1;
      console.log("uploadedPartDatas:", uploadedPartDatas);
      console.log("next get Id:", id);
      for (let i = id - 1; i < parts.length; i++) {
        try {
          // Step 3: Get signed URLs for each part
          const signedUrlsResponse = await apiRequest<any>({
            method: "PUT",
            url: `staff/class/get-part-signed-url`,
            data: {
              fileKey: uploadData.fileKey,
              uploadId: uploadId,
              partNo: id,
            }, // Start with partNo 1
          });

          if (!signedUrlsResponse?.data?.data) {
            throw new Error("Failed to get signed URL");
          } else {
            const partData = await uploadVideo(
              parts[i],
              signedUrlsResponse.data.data
            );

            if (partData.status == 200) {
              uploadedPartDatasTmp.push({
                ETag: partData.headers.etag,
                PartNumber: id,
              });
              setuploadedPartDatas((prev) => [
                ...prev,
                {
                  ETag: partData.headers.etag,
                  PartNumber: id,
                },
              ]);
              setUploading((id / parts.length) * 100);
              console.log("uploadedPartDatas:", uploadedPartDatasTmp);
              id++;
            } else {
              throw new Error("Failed to upload");
            }
          }
        } catch (innerError) {
          // Log or rethrow the error to be caught by the outer catch
          console.error(`Error in part ${i + 1}:`, innerError);
          throw innerError; // Rethrow to propagate to the outer catch block
        }
      }
      completedUploading(uploadId, uploadedPartDatasTmp);
    } catch (error) {
      console.error("Error in uploadPartsHandler:", error);
      setError(true);
    }
  };

  const startUploadHandler = async () => {
    if (!uploadData) return;
    const startUploadingFile = await apiRequest<any>({
      method: "PUT",
      url: "staff/class/start-upload-material",
      data: {
        fileKey: uploadData.fileKey,
        fileType: uploadData.fileType,
      },
    });
    setUploadId(startUploadingFile.data.data.uploadId);
    await uploadPartsHandler(startUploadingFile.data.data.uploadId);
  };

  const completedUploading = async (
    uploadId: string,
    uploadedPartDatas: any[]
  ) => {
    try {
      const completeDataResponse = await apiRequest<any>({
        method: "PUT",
        url: `staff/class/complete-upload-material`,
        data: {
          fileKey: uploadData?.fileKey,
          uploadId,
          parts: uploadedPartDatas,
        }, // Start with partNo 1
      });
      alert('Upload completed')
      setuploadedPartDatas([]);
      setUploadId("");
      setUploadMetaData(null);
      setUploading(0);
    } catch (error) {
      setError(true);
    }
  };

  const splitFileIntoParts = (file: File) => {
    const MAX_PART_SIZE = 100 * 1024 * 1024; // 5MB per part (AWS max part size is 5GB)
    const parts: Blob[] = [];
    let start = 0;
    while (start < file.size) {
      let end = Math.min(start + MAX_PART_SIZE, file.size);
      parts.push(file.slice(start, end));
      start = end;
    }
    return parts;
  };
  const uploadVideo = async (videoBlob: Blob, presignedUrl: string) => {
    const response = await axios.put(presignedUrl, videoBlob, {
      headers: {
        "Content-Type": videoBlob.type, // Set the content type of the Blob
      },
      onUploadProgress(progressEvent) {
        const progress = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total ?? 1)
        );

        // setUploading(progress); // Update progress if needed
      },
    });

    return response;
  };

  useEffect(() => {
    if (uploadData) {
      startUploadHandler();
    
    }
    setuploadedPartDatas([]);
      setUploadId("");
  }, [uploadData]);
  return (
    <ModalBase
      isOpen={!!uploadData}
      onClose={() => setUploadMetaData(null)}
      title="Uploading"
      //   primaryBtn={{
      //     title: "Close",
      //     disabled: uploading != 100,
      //     onClick: () => setUploading(null),
      //   }}
    >
      <div className="w-full max-w-md mx-auto mt-3">
        <div className="bg-gray-200 rounded-full h-6 ">
          <p
            className="bg-green-500 h-6 rounded-full text-xs font-medium text-white text-center  leading-none flex items-center justify-center"
            style={{ width: `${uploading ?? 0}%` }}
          >
            {uploading?.toFixed(1)}%
          </p>
        </div>
        {error && (
          <div className="flex items-center justify-center">
            <p onClick={() => uploadPartsHandler(uploadId)} className="mt-2 underline text-sm cursor-pointer text-blue-500">Retry</p>
          </div>
        )}
      </div>
    </ModalBase>
  );
};

export default UploadingModal;
