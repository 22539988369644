import { useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { TMaterial } from "../../types/Types";
import apiRequest from "../../helpers/apiRequest";
import { Urls } from "../../utils/urls";
import moment from "moment";
import Button from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import ModalBase from "../../components/modals/ModalBase";
import AddMaterialModal from "./components/AddMaterialModal";
import {
  ArrowTopRightOnSquareIcon,
  EyeIcon,
  EyeSlashIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import Loading from "../../components/loading/Loading";
import EditIcon from "../../assets/svgs/EditIcon";
import Pagination from "../../components/pagination/Pagination";

type TModals = {
  add: boolean;
};

const Materials = ({}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState<TModals>({ add: true });
  const [materials, setMaterials] = useState<TMaterial[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const fetchMaterials = async (pageNo = 1, search = "") => {
    setLoading(true);
    const { data, error } = await apiRequest<{
      materials: TMaterial[];
      totalPages: number;
      currentPage: number;
    }>({
      method: "GET",
      url: Urls.all_materials,
      params: {
        limit: 10,
        pageNo,
        search,
      },
    });
    console.log(data);

    if (error) {
      alert(error);
    } else if (data?.materials) {
      setMaterials(data?.materials);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);
    }
    setLoading(false);
  };

  const showOrHideMaterial = async (
    id: string,
    visible: boolean,
    index: number
  ) => {
    const { data, error } = await apiRequest<{ material: TMaterial }>({
      method: "PUT",
      url: Urls.material_visibility,
      data: { id, visible },
    });
    if (error) {
      alert(error);
    } else if (data?.material) {
      updateMaterialStateByIndex(index, { visible });
    }
  };
  const deleteMaterial = async (id: string) => {
    const confirm = window.confirm("Are you sure want to delete?");
    if (!confirm) return;
    const { data, error } = await apiRequest<{ message: string }>({
      method: "DELETE",
      url: `${Urls.one_material}/${id}`,
    });
    if (error) {
      alert(error);
    } else if (data?.message) {
      setMaterials((prev) => prev.filter((a) => a._id != id));
      //   alert(data.message);
    }
  };
  const updateMaterialStateByIndex = (index: number, material: {}) => {
    let tmpMaterials = [...materials];
    tmpMaterials[index] = { ...tmpMaterials[index], ...material };
    setMaterials(tmpMaterials);
  };
  useEffect(() => {
    fetchMaterials();
  }, []);
  if (loading) return <Loading />;
  return (
    <PageLayout
      label="Materials"
      leftComp={
        <Button title="Add" onClick={() => navigate(routes.add_materials)} />
      }
    >
        <div className="flex flex-col h-full pb-24">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Type
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Created By
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Created On
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {materials.map((material, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">{material.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">{material.type}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {material?.createdBy?.email}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {moment(material.createdAt).format("DD - MMMM - YYYY")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center gap-2">
                  <div
                    onClick={() =>
                      showOrHideMaterial(material._id, !material.visible, index)
                    }
                    className="cursor-pointer"
                  >
                    {material.visible ? (
                      <EyeSlashIcon height={18} width={18} title="Hide" />
                    ) : (
                      <EyeIcon height={18} width={18} title="Show" />
                    )}
                  </div>
                  <div
                    onClick={() =>
                      navigate(routes.add_materials, {
                        state: { material, update: true },
                      })
                    }
                    className="cursor-pointer"
                  >
                    <EditIcon size={18} />
                  </div>
                  <TrashIcon
                    color="red"
                    height={18}
                    width={18}
                    title="Delete"
                    className="cursor-pointer"
                    onClick={() => deleteMaterial(material._id)}
                  />{" "}
                  <ArrowTopRightOnSquareIcon
                    height={18}
                    width={18}
                    title="Open"
                    className="cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex-1" />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={fetchMaterials}
        />
        </div>
      {/* <AddMaterialModal isOpen={modals?.add}
                onClose={() => setModals((prev) => ({ ...prev, modals: false }))}
                 /> */}
    </PageLayout>
  );
};

export default Materials;
